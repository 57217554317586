.site-container {
  .order-details-page {
    .cart-item {
      .cart-item__thumb {
        margin-right: 0;
      }
    }
    .product-header {
      .tracking {
        margin-right: 16.66667%;
      }
      .product {
        margin-right: 0;
      }
    }
  }
  .viewcart {
    #shipmethod-panel {
      #checkout_shipmethod {
        padding-right: 0;
      }
    }
    #favorites-panel {
      .product-list {
        .btn-mini {
          line-height: 15px;
          white-space: pre-line;
        }
      }
    }
    #top-viewcart-buttons {
      width: 68%;
      @include breakpoint(($width-large - 1 $width-xlarge + 1) (orientation landscape)) {
        width: 69%;
      }
      @include breakpoint($bp--large-down) {
        width: 100% !important;
      }
      .continue-buttons {
        padding: 0 0 0 0;
        @include breakpoint($bp--large-down) {
          text-align: center;
        }
      }
    }
    #bottom-viewcart-buttons {
      .continue-buttons {
        padding-#{$rdirection}: 8%;
        @include breakpoint($bp--large-down) {
          width: 100% !important;
          text-align: center;
        }
        @include breakpoint($width-large - 1 $width-xlarge + 1) {
          padding-#{$rdirection}: 6%;
        }
      }
    }
  }
  .checkout {
    .checkout__content {
      .loading {
        float: none;
        margin-right: 0;
      }
      .cart-header__title--products {
        margin-right: 0;
      }
      .order-summary__content {
        .value {
          margin-right: 78.33333%;
          width: 15.33333%;
        }
      }
    }
    .new-address {
      .field-container--grid {
        .form-item {
          &:nth-of-type(2n + 1) {
            .valid_marker,
            .invalid_marker {
              left: 18px;
              right: auto;
            }
          }
        }
      }
    }
    .product__thumb {
      margin-right: 0;
    }
    .checkout__new-account {
      margin-right: 0;
    }
    #checkout_complete {
      .picker-radio {
        float: right;
      }
    }
  }
  .viewcart,
  .checkout {
    .checkout {
      &__sidebar {
        #offer-code-panel {
          .offer-code {
            &__input {
              label {
                text-align: #{$ldirection};
              }
            }
          }
        }
      }
    }
  }
}

.checkout {
  .samples-page {
    .samples-panel__continue-checkout--button {
      float: left;
    }
  }
}

.recommended-products-panel {
  .checkout__panel-content {
    .recommended-product-items {
      .recommended-item {
        margin-right: 0;
      }
    }
  }
}

[dir='rtl'] {
  .column {
    &.right {
      #order-summary-panel {
        .order-summary__content {
          .label {
            margin-#{$ldirection}: 0;
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
  }
  .checkout {
    .samples-page {
      .samples-grid {
        .samples-grid--item:nth-of-type(4n + 1) {
          margin-right: 0;
        }
      }
    }
  }
}

.click-and-collect {
  .picker {
    display: none;
  }
  .form-item {
    span {
      display: none;
    }
  }
  .title {
    display: none !important;
  }
}

#checkout_shipping_panel {
  .click-and-collect {
    .field-container--grid {
      .form-item:nth-of-type(2n + 2) {
        float: right;
        padding-left: 1em;
      }
      .form-item {
        .valid_marker,
        .invalid_marker {
          left: 18px;
          right: auto;
        }
      }
    }
  }
}
