.INDblackwhite {
  .picker.checked {
    .picker-flag {
      background-color: white !important;
    }
  }
}

.INDhighlightHeaders {
  .js-customer-service,
  .js-call-us,
  .js-live-chat,
  .js-email-artist {
    .title {
      border-bottom: 2px solid #222;
    }
  }
  .narrowed_body_copy {
    h3:nth-of-type(1) {
      border-bottom: none !important;
    }
  }
  .text-wrap {
    width: 17% !important;
  }
  .expando-block__title {
    font-weight: bold;
    border-bottom: 2px solid #222;
  }
  .site-my-mac__contents-title {
    font-weight: bold !important;
    border-bottom: 2px solid #222;
    width: 45%;
  }
  .site-my-mac__contents-footer-title {
    border-bottom: 2px solid #222 !important;
    width: 80%;
  }
  .BVMESectionHeader {
    font-weight: bold;
    border-bottom: 2px solid #222 !important;
  }
  #viewcart-panel {
    .cart-items {
      .cart-header__title {
        text-decoration: underline;
      }
    }
  }
  .site-container {
    .product__reviews-spp {
      .BVPagePreviewReview {
        .BVMEPageHeader {
          border-bottom: 2px solid #222 !important;
          width: 33%;
        }
      }
    }
  }
  #BVDivCustomTagHeaderID {
    border-bottom: 2px solid #222 !important;
    width: 33%;
  }
}

.INDhighlightLinks {
  .product__name,
  .product__description-trigger-title {
    border-bottom: 2px solid red;
  }
  a.product__product-life-tooltip {
    border-bottom: 10px solid red !important;
  }
  .site-container {
    .product__reviews-spp {
      .BVPagePreviewReview {
        .BVFooter {
          .BVModuleButtons {
            .BVButton {
              color: Red;
              border-bottom: 2px solid red;
            }
          }
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    .product-page-livechat-hitarea-mask {
      border-bottom: 2px solid red !important;
      width: auto;
      top: 0 !important;
    }
  }
  .site-my-shades-v1 {
    .my-shades {
      border-bottom: none;
    }
  }
  .block-image-hover__title-inner {
    border-bottom: 2px solid red;
    color: red;
  }
  .block-image-hover__excerpt,
  .site-navigation__submenu-tout-label,
  .lp_top-text {
    color: red !important;
  }
  .header-search {
    .header-search__form--content {
      border-bottom: 2px solid red;
    }
  }
  .site-header {
    .site-header__tools {
      .block-template-site-my-shades-v1 {
        .my-shades {
          border-bottom: none !important;
        }
      }
    }
  }
}

.INDblackcursor {
  .product__description-trigger-title,
  .offer-code-panel__title,
  .favorites-panel__title,
  .btn--full,
  .picker.picker-radio,
  .picker.picker-checkbox,
  .picker-flag,
  .account-photo-upload-txt-btn,
  .product-page-livechat-hitarea-mask,
  .icon--remove,
  .shade-picker__color-actions,
  .media-block__caption,
  .block-image-hover__link {
    cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
  }
}

.INDwhitecursor {
  .product__description-trigger-title,
  .offer-code-panel__title,
  .favorites-panel__title,
  .btn--full,
  .picker.picker-radio,
  .picker.picker-checkbox,
  .picker-flag,
  .account-photo-upload-txt-btn,
  .product-page-livechat-hitarea-mask,
  .icon--remove,
  .shade-picker__color-actions,
  .media-block__caption,
  .block-image-hover__link {
    cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
  }
}

.site-header {
  .site-header__tools {
    .site-bag__link-icon {
      position: relative !important;
      top: 0px !important;
      margin: 0 -24px 7px 0 !important;
    }
    span.site-bag__link-count {
      margin-top: -60px;
      margin-right: 0px;
    }
  }
}

.INDhighlightLinks {
  a.site-bag__link,
  #site-email-signup {
    height: 60px;
    display: block;
  }
  input[type='submit'],
  button[type='button'],
  div#choose-file-btn,
  div.btn,
  .expando-block__title {
    color: red !important;
    border-bottom: 2px solid red !important;
    text-decoration: none;
  }
  .profile-pic-upload__form-wrapper {
    div#choose-file-btn {
      span {
        color: red !important;
      }
    }
  }
  .grid--mpp__item {
    .product {
      &.prod_inv_status-7 {
        a {
          border-bottom: none !important;
        }
      }
    }
  }
  .product__faves--teaser {
    .product__add-to-faves {
      top: 4.2em !important;
    }
  }
  #lpChat {
    .lp_header-buttons-container,
    .lp_buttons_area {
      button {
        color: red !important;
        border-bottom: 2px solid red !important;
      }
    }
  }
  .product__social-links,
  .text-block__cta {
    border-bottom: 2px solid red !important;
  }
  section.favorites-board {
    .favorites__social-links {
      li {
        color: red !important;
        border-bottom: 2px solid red !important;
      }
    }
  }
  .tout-block-portrait__cta {
    color: Red !important;
    border-bottom: 2px solid red !important;
  }
  span.block-image-hover__link {
    color: Red;
  }
  .site-banner {
    .site-banner-close {
      i.icon--remove {
        color: Red;
        border-bottom: 2px solid red;
      }
    }
  }
  .site-navigation__submenu-tout-label {
    color: Red;
  }
  .tout-block-landscape__body-above {
    img {
      border-bottom: 2px solid red !important;
    }
  }
  .site-container {
    .view-all-shades {
      .view-all-shades__icon {
        border-bottom: 2px solid red;
        height: 3%;
      }
    }
  }
  .add-to-bag-bar__add-button-button {
    color: Red;
    border-bottom: 2px solid red;
  }
  .product__bag-bar--left-arrow {
    .add-to-bag-bar__icon {
      border-bottom: 2px solid red;
      height: 27%;
    }
  }
  .writeareview_BVRR {
    .BVModuleButtons {
      button.BVButton {
        color: Red !important;
        border-bottom: 2px solid red !important;
      }
    }
  }
}

html:not(#INDdummy) {
  body:not(#INDdummy).INDreadable > :not(#INDWrap) {
    .icon--search,
    .icon--envelope,
    .icon--shopping-bag,
    .icon--bars,
    .icon--remove,
    .icon--instagram,
    .icon--twitter,
    .icon--pinterest,
    .icon--tumblr,
    .icon--youtube,
    .icon--facebook {
      font-family: icons !important;
    }
    .product__faves--teaser,
    .product__add-to-faves {
      .icon--heart-o {
        font-family: icons !important;
      }
    }
    .footer-social-links-v1 {
      .icon--facebook,
      .icon--twitter,
      .icon--youtube,
      .icon--instagram,
      .icon--tumblr,
      .icon--pinterest {
        font-family: icons !important;
      }
    }
    .product__social-links {
      .icon--envelope,
      .icon--facebook,
      .icon--twitter,
      .icon--pinterest {
        font-family: icons !important;
      }
    }
    .shade-picker {
      .shade-picker__controls {
        .icon--remove,
        .icon--arrow--up,
        .icon--arrow--down {
          font-family: icons !important;
        }
      }
    }
  }
}

.INDwhiteblack,
.INDblackwhite {
  .text-wrap {
    background-color: transparent !important;
    width: 17% !important;
  }
  li.expanded {
    padding: 0;
  }
  .icon--shopping-bag {
    background: transparent !important;
  }
  .shade-picker__color-name {
    &:hover {
      background-color: lemonchiffon !important;
    }
    span:hover {
      background-color: lemonchiffon !important;
    }
  }
  .shade-picker__color-actions {
    width: 42%;
    float: left;
    right: 57%;
    a:hover {
      background-color: lemonchiffon !important;
    }
    &:hover {
      background: none;
    }
  }
}

.INDwhiteblack {
  .site-logo__image {
    background-color: black !important;
  }
  header.site-header {
    .site-banner-copy {
      .icon--remove {
        outline: 1px dashed MidnightBlue;
      }
    }
  }
  .narrowed_body_copy {
    h4 {
      span {
        color: MidnightBlue !important;
      }
    }
  }
  .media-block__media,
  .block-image-overlay__image,
  .multi-use-tout__image {
    -moz-filter: contrast(1.5);
    -ms-filter: contrast(1.5);
    -o-filter: contrast(1.5);
    filter: contrast(1.5);
  }
  #checkout_complete {
    #continue-btn {
      .btn-mini {
        outline: 1px dashed MidnightBlue;
      }
      .btn-mini:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  #samples-panel {
    .js-continue-checkout-btn {
      .btn-primary {
        outline: 1px dashed MidnightBlue;
      }
      .btn-primary:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  .recommended-item {
    .addtobag {
      .btn-mini {
        outline: 1px dashed MidnightBlue;
      }
      .btn-mini:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  .return-user__fieldset,
  .new-account__fieldset {
    .form-item {
      .btn {
        outline: 1px dashed MidnightBlue;
      }
      .btn:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  .node-43062 {
    .custom-grid {
      .carousel-controls {
        .slick-prev:before,
        .slick-next:before {
          color: black !important;
        }
      }
    }
  }
  #node-43002 {
    .slick-dots {
      z-index: 1 !important;
    }
    .media-block__body {
      margin: 0.5em 0 !important;
    }
  }
  .basic-text-block-v1 {
    .text-block__cta {
      color: black !important;
    }
  }
  figcaption {
    &.multi-use-tout__caption {
      opacity: 0;
    }
  }
  .shade-picker__close {
    .icon--remove {
      outline: 1px dashed MidnightBlue;
    }
    .icon--remove:before {
      color: black;
    }
  }
}

.INDblackwhite {
  header.site-header {
    .site-banner-copy {
      .icon--remove {
        outline: 1px dashed Gold;
      }
    }
  }
  .basic-carousel-v1 {
    .carousel-slide {
      .media-block__body {
        margin: 8px 0 4px 0 !important;
      }
    }
  }
  .custom-grid {
    .carousel {
      &.carousel--small-controls {
        .slick-dots {
          z-index: 10;
        }
      }
    }
  }
  .media-block__media,
  .block-image-overlay__image,
  .multi-use-tout__image {
    -webkit-filter: invert(1);
    -moz-filter: invert(1);
    -ms-filter: invert(1);
    -o-filter: invert(1);
  }
  #checkout_complete {
    #continue-btn {
      .btn-mini {
        outline: 1px dashed Gold;
      }
      .btn-mini:hover {
        color: black !important;
        background-color: lemonchiffon !important;
      }
    }
  }
  #samples-panel {
    .js-continue-checkout-btn {
      .btn-primary {
        outline: 1px dashed Gold;
      }
      .btn-primary:hover {
        color: black !important;
        background-color: lemonchiffon !important;
      }
    }
  }
  .recommended-item {
    .addtobag {
      .btn-mini {
        outline: 1px dashed Gold;
      }
      .btn-mini:hover {
        color: black !important;
        background-color: lemonchiffon !important;
      }
    }
  }
  .return-user__fieldset,
  .new-account__fieldset {
    .form-item {
      .btn {
        outline: 1px dashed Gold;
      }
      .btn:hover {
        color: black !important;
        background-color: lemonchiffon !important;
      }
    }
  }
  figcaption {
    &.multi-use-tout__caption {
      opacity: 0;
    }
  }
}

#product-page-livechat-hitarea {
  height: auto !important;
  .product-page-livechat-hitarea-mask {
    top: 2px !important;
    img {
      height: 2.33em;
    }
  }
}

.INDfontsize15 {
  .product__description-short {
    margin: 0px 0 6px 0px !important;
    font-size: 15px !important;
  }
  .sample-select-container {
    padding: 0em 0px !important;
  }
  .product__name {
    font-size: 23px !important;
  }
  .store-locator {
    .location_info-col1 {
    }
    .store_name {
      font-size: 25.5px !important;
    }
    .location_info-col2 {
      font-size: 25.5px !important;
    }
  }
  .mac-tooltip {
    .tooltipster-content {
      font-size: 25.5px !important;
    }
  }
  .site-header {
    .site-header__prefix {
      width: auto !important;
    }
  }
  .search-results {
    .search-filter__trigger {
      width: 28%;
    }
  }
}

.INDfontsize11,
.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .site-header {
    .site-header__tools {
      .site-bag__contents {
        font-size: 1em !important;
        .site-bag__contents-header {
          .site-bag__contents-title {
            font-size: 2em !important;
          }
        }
        .site-bag__contents-products {
          .cart-product-name,
          .cart-product-price {
            font-size: 1.5em !important;
          }
        }
        .site-bag__contents-footer {
          .site-bag__contents-subtotal,
          .site-bag__contents-checkout,
          .site-bag__contents-message {
            font-size: 1.5em !important;
          }
        }
      }
    }
  }
}

.INDmonochrome {
  .site-header {
    z-index: 1 !important;
  }
}

.INDfontsize11,
.INDfontsize12 {
  .product__name {
    font-size: 21px !important;
  }
}

.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .product__name {
    font-size: 22px !important;
  }
  .search-filter__trigger_icon--right {
    bottom: 10px !important;
  }
  .site-container {
    .view-all-shades {
      .view-all-shades__icon {
        font-size: 16px !important;
      }
    }
  }
  .site-header {
    li.site-navigation__item {
      padding: 0 20px !important;
    }
  }
}

.INDfontsize11 {
  .store-locator {
    .location_info-col1 {
    }
    .store_name {
      font-size: 18.7px !important;
    }
    .location_info-col2 {
      font-size: 18.7px !important;
    }
  }
  .mac-tooltip {
    .tooltipster-content {
      font-size: 18.7px !important;
    }
  }
  .product__description-short {
    margin: 0px 0 6px 0px !important;
  }
}

.INDfontsize12 {
  .store-locator {
    .location_info-col1 {
    }
    .store_name {
      font-size: 20.4px !important;
    }
    .location_info-col2 {
      font-size: 20.4px !important;
    }
  }
  .mac-tooltip {
    .tooltipster-content {
      font-size: 20.4px !important;
    }
  }
  .product__description-short {
    font-size: 13.6px !important;
    margin: 0px 0 6px 0px !important;
  }
}

.INDfontsize13 {
  .store-locator {
    .location_info-col1 {
    }
    .store_name {
      font-size: 22.1px !important;
    }
    .location_info-col2 {
      font-size: 22.1px !important;
    }
  }
  .mac-tooltip {
    .tooltipster-content {
      font-size: 22.1px !important;
    }
  }
  .product__description-short {
    margin: 0px 0 6px 0px !important;
    font-size: 14.6px !important;
  }
  .product__name {
    font-size: 23px !important;
  }
}

.INDfontsize14 {
  .store-locator {
    .location_info-col1 {
    }
    .store_name {
      font-size: 23.8px !important;
    }
    .location_info-col2 {
      font-size: 23.8px !important;
    }
  }
  .mac-tooltip {
    .tooltipster-content {
      font-size: 23.8px !important;
    }
  }
  .product__description-short {
    margin: 0px 0 6px 0px !important;
    font-size: 14.6px !important;
  }
  .product__name {
    font-size: 23px !important;
  }
}

@include breakpoint($bp--large-down) {
  #INDmenu-btn {
    bottom: 170px !important;
  }
}

.INDmagnifier {
  .site-header__menu {
    .site-navigation {
      width: 65% !important;
      .site-navigation__menu {
        li.site-navigation__item,
        li.site-navigation__more {
          padding: 0 10px !important;
        }
      }
    }
  }
  .site-header__suffix {
    .block-template-site-my-mac-v1 {
      width: 30% !important;
    }
    .block-template-site-my-shades-v1 {
      width: 30px !important;
    }
  }
  .site-header__suffix {
    .site-email-signup {
      padding: 0 24px !important;
      min-width: 75px !important;
    }
  }
  .checkout__content {
    .panel {
      .btn {
        min-width: 180px !important;
      }
    }
  }
  .search-results {
    .search-filter__trigger {
      width: 28%;
    }
  }
}

@-moz-document url-prefix() {
  .INDmagnifier {
    .site-container {
      margin-top: 60px !important;
    }
    .site-footer {
      margin-top: 735px !important;
    }
  }
}

.INDwhiteblack.INDhighlightLinks {
  .writeareview_BVRR {
    .BVModuleButtons {
      button.BVButton {
        border-bottom: 2px solid red;
        .BVLinkSpan {
          color: red !important;
        }
      }
      button.BVButton:hover {
        background-color: lemonchiffon !important;
        .BVLinkSpan {
          color: black !important;
          background: lemonchiffon !important;
        }
      }
    }
  }
}

.INDmagnifier {
  .site-footer {
    .checkout__content {
      .recommended-products-panel {
        margin-bottom: 585px;
      }
    }
  }
  .site-container {
    max-width: 906px;
  }
  .btn--fixed_width {
    width: 180px;
  }
  .product__detail {
    .product__images {
      width: 804px;
      position: absolute;
      z-index: -9;
      right: -24%;
    }
  }
  @media all and(min-width: 60.43em) {
    .site-footer {
      margin-top: 735px !important;
    }
  }
}

.INDblackwhite {
  @media all and(min-width: 60.43em) {
    .site-container,
    .return-user,
    .new-account,
    .sign-in-page,
    .section-head__header,
    .field-container,
    .new-account__fieldset,
    .return-user__fieldset {
      background-color: black !important;
    }
  }
}

#INDbtnWrap {
  #INDmenu-btn {
    svg {
      height: 35px;
    }
  }
}
