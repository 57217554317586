.site-header {
  .site-bag {
    &__contents {
      &-header {
        .site-bag {
          &__contents-title {
            text-align: #{$ldirection};
          }
        }
      }
    }
  }
  .site-my-mac__contents {
    #{$ldirection}: auto !important;
  }
}

.site-container {
  .favorites-page {
    .favorites-mylists {
      .carousel-slide {
        .lists {
          &__link span {
            display: inline-block;
          }
        }
      }
    }
    .favorites-board {
      .product {
        &__description-short {
          word-break: break-word;
        }
      }
      &__list {
        .pyramid-grid {
          .grid--item {
            margin-bottom: 70px;
            @include breakpoint($bp--large-up) {
              &:nth-of-type(4n + 4) {
                margin-#{$ldirection}: 0;
                margin-#{$rdirection}: 0;
                padding-#{$rdirection}: 1.5em;
                padding-#{$ldirection}: 0;
              }
              &:nth-of-type(4n + 3) {
                padding-#{$rdirection}: 1em;
                padding-#{$ldirection}: 0.5em;
              }
              &:nth-of-type(4n + 2) {
                padding-#{$rdirection}: 0.5em;
                padding-#{$ldirection}: 1em;
              }
              &:nth-of-type(4n + 1) {
                margin-#{$ldirection}: 0;
                padding-#{$rdirection}: 0;
                padding-#{$ldirection}: 1.5em;
              }
            }
            @include breakpoint($bp--medium-up) {
              @include breakpoint($bp--large-down) {
                &:nth-of-type(3n + 3) {
                  margin-#{$rdirection}: 0;
                }
                &:nth-of-type(3n + 1) {
                  margin-#{$ldirection}: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .past-purchases {
    .product__footer {
      .product-item {
        &__coming-soon,
        &__out-of-stock {
          position: absolute;
          top: 56px;
          #{$rdirection}: 0;
          #{$ldirection}: auto;
          height: auto;
        }
      }
      a.notify_me {
        top: 0;
        margin: 18px 0;
      }
    }
    .products {
      li.product {
        margin: 0 !important;
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .product-header {
          .product {
            margin-#{$ldirection}: 0;
          }
        }
        .product--teaser {
          .product__image {
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
  }
  .address-book-page__content.panel .address-book {
    margin-#{$ldirection}: 0;
  }
  .custom-grid {
    .custom-grid__row:nth-child(2) {
      .custom-grid__item.unique-snowflake {
        margin-#{$rdirection}: 0px;
      }
    }
  }
  .select2-container {
    .select2-choice {
      .select2-arrow b {
        transform: rotate(90deg) !important;
      }
    }
  }
  .form_title {
    span {
      display: inline-block;
    }
  }
  .layout--artistry-artist {
    .product--teaser {
      .product__footer {
        .product__price {
          float: right;
        }
      }
    }
  }
  @include breakpoint($bp--medium-down) {
    .product--teaser {
      padding-top: 10px;
      min-height: 520px;
    }
  }
}

.site-footer {
  &--top .grid-container--6 .grid--item {
    text-align: #{$ldirection};
  }
}

body:not(.page-product) {
  .carousel-controls {
    .slick-prev {
      #{$ldirection}: auto !important;
      #{$rdirection}: 15px !important;
    }
    .slick-next {
      #{$rdirection}: auto !important;
      #{$ldirection}: 15px !important;
    }
  }
}

html {
  overflow-x: hidden;
}

.artistry-video-player-wrapper {
  .artistry-video-player__products {
    .video-player__products-carousel {
      .product__footer {
        a.product__link-to-spp {
          padding: 0 5px;
          font-size: 14px;
        }
      }
    }
  }
}
