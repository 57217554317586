@if $password_strengthen {
  .new-account {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          bottom: 32%;
        }
        &:before {
          @include breakpoint($bp--medium-up) {
            -ms-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .password-field {
    &__info {
      &:before {
        @include breakpoint($bp--medium-up) {
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }
  .appt-book-section {
    .password-field {
      &__info {
        @include breakpoint($bp--medium-up) {
          &:before {
            @include breakpoint($bp--medium-up) {
              -ms-transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
