//  @file il_pc.scss
//  \brief Pull all parts of the IL-PC theme together into one file

@import 'overrides/overrides';
$text-direction: rtl;
$rdirection: left;
$ldirection: right;
$password_strengthen: true;

@import '../../emea_base/scss/emea_base_pc';
@import 'sections/_gwp_pc';
@import 'sections/all';

// SMACSS/sections/account
@import 'sections/pc/account/account';
@import 'sections/pc/account/account_reset_password';

// SMACSS/Components/checkout
@import 'sections/pc/checkout/checkout';
@import 'sections/enhanced-delivery';

// Ratings and Reviews Implementation for arabic
@import 'sections/ratings_and_reviews';

//IL Accessibility overrides
@import 'sections/pc/accessibility';

// Power Review
@import 'sections/power_review';

// Header Search overrides
@import 'sections/pc/header_search';

// Font Variables and path to Net Storage
@import '_fonts';

// One Trust Cookie Styles.
@import '../../mac_base/scss/onetrust_cookie/cookie_settings_ot';
@import '../../emea_base/scss/sections/cookie_settings_ot';

// Hebrew overrides to swap out Mac base font for 'Assistant Regular'
body,
.product--teaser.product--shaded .product__link-to-spp,
.btn,
.product__description-short,
.custom-text,
.product-brief-v2 .product-brief__description,
.toggle,
.site-header,
.site-header .site-header__tools .site-my-mac__link,
.header-offers-banner-item,
.header-search .header-search__field,
.product-brief,
#pr-reviewdisplay .p-w-r .pr-review-display .pr-rd-no-reviews .pr-snippet-write-review-link {
  font-family: $AssistantRegular;
}

// Hebrew overrides to swap out Mac base font for 'Assistant Bold'
.title--h5,
h1,
h2,
h3,
.product-brief-v2 .product-brief__cta .product-vto-link,
.product-brief .product-add-to-bag,
.product-brief-cta,
.product-brief-v2 .product-brief__cta .product-add-to-bag,
.site-footer--bottom,
.product-vto .cta-vto,
.product-full__overview-trigger span,
.product-brief-v2 .product-brief__cta .product-vto-link,
.elc-link,
.link,
.elc-link--underline,
.link--underline,
.title-02--bold,
.title-03--bold,
.sub-title--bold,
.body-text--bold,
.body-text--small-bold,
.legal-text--bold {
  font-family: $AssistantRegular;
}
