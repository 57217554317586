[dir='rtl'] {
  select {
    @include background(url(image-url('fallbacks/corner-triangle-reversed.png')));
    background-position: 5% 54%;
  }
  .shade-picker-dropdown {
    .select2-container {
      .select2-choice {
        .select2-arrow {
          b {
            transform: rotate(225deg) !important;
          }
        }
        .select2-chosen {
          margin-#{$ldirection}: 8px;
        }
      }
    }
  }
  .collection-tile-formatter {
    .collection-tile-formatter-inner {
      .cl-tile:nth-of-type(9) {
        margin-left: 0;
      }
    }
  }
}

#toolbar {
  #togglemenus {
    ul {
      .has-sub {
        div {
          position: static;
        }
      }
      .mobile-preview {
        ul {
          #{$rdirection}: -160px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .align-r {
      #{$rdirection}: 0;
      #{$ldirection}: auto;
      .input-btn {
        margin: 0;
      }
    }
  }
  h4.waitlist_thankyou_heading {
    margin: 0;
  }
}

.store-locator {
  .results_panel {
    .results_side {
      .door_row {
        td {
          text-align: #{$ldirection};
        }
      }
    }
  }
}

#csr_header {
  #{$rdirection}: 0;
  .csr_table {
    td {
      text-align: #{$ldirection};
    }
  }
}

.site-container {
  #csr_header_holder {
    top: 65px;
    z-index: 9998;
  }
  .contact-us-page {
    .contact-nav {
      li {
        .details {
          min-height: 170px;
          margin-bottom: 90px;
        }
        .title {
          min-height: 65px;
          @include breakpoint($bp--xlarge-down) {
            min-height: 65px;
          }
        }
        min-height: 260px;
        margin-bottom: 1px;
        &:nth-of-type(4n + 2) {
          padding-#{$ldirection}: 0;
        }
        &:nth-of-type(4n + 3) {
          padding-#{$ldirection}: 0;
        }
        @include breakpoint($bp--medium-down) {
          &:nth-of-type(2n + 1) {
            margin-#{$ldirection}: -10px;
          }
          &:nth-of-type(4n + 3) {
            border-top: none;
            margin-#{$ldirection}: 50%;
          }
        }
      }
    }
  }
  .sec-nav__item--has-sub-nav {
    .sec-nav__link {
      &:before {
        transform: rotate(225deg);
      }
    }
  }
  .layout--artistry-artist {
    .shades {
      .product--shaded,
      .product--teaser {
        .product__footer {
          @include breakpoint($bp--large-up) {
            a.product__add-to-bag {
              font-size: 15px;
            }
            a.notify_me {
              padding: 0 15px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
  .view-all-shades {
    .view-all-shades__icon {
      transform: rotate(180deg);
    }
  }
  .product__sticky-container {
    .add-to-bag-bar__shade-picker:after {
      transform: rotate(225deg);
    }
  }
  .product__bag-bar--left-arrow {
    .add-to-bag-bar__icon:before {
      transform: rotate(180deg);
    }
  }
  #search-wrapper {
    #zero-results {
      .result-larger {
        font-size: 1.3rem;
      }
    }
  }
  .collection-tile-formatter {
    .cl-tile:nth-child(3) {
      margin-left: 0 !important;
    }
  }
  .grid--mpp-3across {
    .grid--mpp__item:nth-of-type(3n + 3) {
      margin-left: 0 !important;
    }
  }
  .sec-nav {
    .sec-nav_wrapper {
      .sec-nav__title_name {
        &::after {
          @include swap_direction(padding, 0 0 0 10px);
        }
      }
    }
  }
  .grid--mpp {
    &__item {
      .product--teaser {
        .product {
          &__footer {
            .product {
              &__inventory-status {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

.artistry-videos-formatter-v1 {
  .artistry-video-section {
    &__grid {
      .grid--item:nth-of-type(1n) {
        margin-#{$rdirection}: 0px;
      }
    }
  }
}

.site-footer {
  .site-footer--top {
    .grid-container--6 .grid--item {
      &:nth-of-type(6n + 1) {
        width: 20%;
      }
      &:nth-of-type(6n + 2) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 20%);
      }
      &:nth-of-type(6n + 3) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 40%);
      }
      &:nth-of-type(6n + 4) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 60%);
      }
      &:nth-of-type(6n + 5) {
        width: 20%;
        @include swap_direction(margin, 0 -100% 0.5em 80%);
      }
    }
  }
}

.site-container {
  .mpp-custom--custom-palette {
    .grid--mpp__item {
      .product__header {
        .product__name {
          white-space: nowrap;
        }
      }
    }
  }
  .layout--collection-detail {
    .grid--mpp {
      .product {
        .product__detail {
          .product__footer {
            a.notify_me {
              width: auto;
              line-height: 30px;
              top: 0%;
            }
          }
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    width: auto;
  }
}

.site-footer--bottom {
  .field-menu {
    .menu {
      text-align: center;
    }
  }
}

@include breakpoint($bp--small-up) {
  .site-footer {
    .site-footer--top {
      .block--footer-mobile-help {
        .btn {
          font-size: 21px;
        }
      }
    }
  }
}

.site-header {
  .site-header__tools {
    .site-bag__contents {
      .site-bag__contents-products {
        .cart-product-img {
          float: left;
        }
      }
    }
  }
}

[dir='rtl'] {
  .layout--artistry-artistry__videos {
    .artistry--mosaic-tile:nth-of-type(2n + 2) {
      margin-#{$ldirection}: -100%;
      margin-#{$rdirection}: 0;
    }
  }
}

html {
  overflow-x: -moz-hidden-unscrollable;
}

#BVRRSummaryContainer {
  .BVRRRatingSummary {
    @include swap_direction(padding, 1.5em 0);
  }
}

@include breakpoint($bp--large-up) {
  #BVRR_Container {
    .BVRRRatingSummaryStyle2 {
      .BVRRRatingSummaryNoReviews {
        .BVRRRatingSummaryLinkWriteFirst {
          display: none;
        }
      }
    }
  }
}

.search-filter__trigger {
  .search-filter__trigger_icon--right,
  .search-filter__trigger_icon--left {
    transform: rotate(180deg);
  }
}

.text-block--quote {
  .text-block__body {
    padding: 0;
    text-indent: 0;
  }
}

.appt-book {
  .cal-head-content {
    .cal-copy {
      background-image: url(/sites/maccosmetics/themes/mac_base/images/fallbacks/corner-triangle-reversed.png) !important;
      background-position: 5% 54% !important;
    }
    #appt-book-date-picker-trigger {
      background-position: right;
    }
  }
  #booking-step2 {
    .store-info-content {
      margin: 0 auto;
      text-align: center;
      padding-top: 20px;
      width: 50%;
    }
  }
  #booking-step3 {
    .appt-book-register-password-text {
      margin: 15px 0 !important;
    }
    .store-info-content {
      margin-bottom: 40px;
    }
  }
  .appt-book-location-selector {
    .form-container {
      .ui-select {
        padding-bottom: 20px;
      }
      .location-submit {
        margin: 40px auto 110px;
      }
    }
  }
  #appt-book-datepicker-overlay {
    .overlay-content-container {
      .overlay-close {
        left: 10px;
      }
    }
  }
  .appt-book-page-header {
    .appt-book-page-header-content__subhead {
      margin-top: 15px;
    }
  }
  .appointments-container {
    .appointment-select {
      .calendar-head {
        .cal-head-controls {
          .cal-controls.previous {
            background: transparent
              url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_right.png) no-repeat
              right center !important;
          }
          .cal-controls.next {
            background: transparent
              url(/media/export/cms/makeup_services/appointment_booking/appointments/cal_arrow_left.png) no-repeat left
              center !important;
          }
        }
      }
    }
  }
  .my-appointments {
    .appointment-details {
      .date-time {
        .appt-date-cal {
          width: 50%;
        }
      }
    }
  }
}

.custom-kit-formatter {
  &__landing-content & {
    &__content-wrapper {
      #{$ldirection}: 15%;
    }
  }
  & &__overlay {
    &.active {
      #{$rdirection}: 50%;
      #{$ldirection}: auto;
    }
  }
}
